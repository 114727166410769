<template>
<div>
  <SpecialistShopHeader/>
  <div class="checkout__wrp">
    <h1>{{selectLang.title}}</h1>
    <div class="checkout__row">
      <div class="products__col">
        <div class="card" v-for="card in productStore" :key="card.id">
          <div class="trash" @click="remove({id: card.id})">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 4L4.69444 15.1111C4.72743 15.7531 5.19444 16.2222 5.80556 16.2222H12.1944C12.808 16.2222 13.2663 15.7531 13.3056 15.1111L14 4" stroke="#515076" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M2.88867 4H15.1109Z" fill="#515076"/>
              <path d="M2.88867 4H15.1109" stroke="#515076" stroke-miterlimit="10" stroke-linecap="round"/>
              <path d="M6.77735 4V2.61111C6.77703 2.50158 6.79836 2.39308 6.84013 2.29183C6.88189 2.19058 6.94326 2.09858 7.02071 2.02114C7.09816 1.94369 7.19015 1.88232 7.2914 1.84055C7.39265 1.79879 7.50116 1.77745 7.61068 1.77777H10.3885C10.498 1.77745 10.6065 1.79879 10.7077 1.84055C10.809 1.88232 10.901 1.94369 10.9784 2.02114C11.0559 2.09858 11.1172 2.19058 11.159 2.29183C11.2008 2.39308 11.2221 2.50158 11.2218 2.61111V4" stroke="#515076" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9 6.22223V14" stroke="#515076" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6.5 6.22223L6.77778 14" stroke="#515076" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11.5004 6.22223L11.2227 14" stroke="#515076" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="img__cont">
            <img :src="card.image_thumbnail" alt="image card">
          </div>
          <div class="details">
            <div class="title">
              {{ card.title }} {{card.weight_packaging}}
              <span class="red_text" v-if="card.discount">{{card.discount}} %</span>
            </div>
            <div class="btm">
              <div class="price">
                {{ card[productCurrency] * card.cnt }} {{currency}}
              </div>
              <div class="old_price" v-if="card.discount">{{card[PromoProductCurrency] * card.cnt}} {{currency}}</div>
              <div class="qty">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="minus" @click="setCnt({id: card.id, cnt: card.cnt - 1})">
                  <rect x="6" y="11" width="12" height="2" rx="1" fill="#000001"/>
                </svg>
                <div class="count">
                  {{ card.cnt }}
                </div>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="plus" @click="setCnt({id: card.id, cnt: card.cnt + 1})">
                  <rect x="6" y="11" width="12" height="2" rx="1" fill="#000001"/>
                  <rect x="11" y="6" width="2" height="12" rx="1" fill="#000001"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- https://paynet.md/acquiring/setecom -->
      <!-- paymentSystem -->
      <form ref="formByCourse" method="POST" :action="paymentSystem" enctype="application/json">
        <div class="card">
          <strong>1. {{ $t('basket.date.title') }}</strong>
          <div class="inp__row">
            <input type="text" :placeholder="$t('basket.date.name')" v-model="formData.name" :class="{'warning': !regexp.name.status }">
            <input type="text" :placeholder="$t('basket.date.surname')" v-model="formData.surname" :class="{'warning': !regexp.surname.status }">
          </div>
          <input class="default_input" type="text" :placeholder="$t('basket.date.phone')" v-model="formData.phone" :class="{'warning': !regexp.phone.status }">
          <template v-if="specialistLocation == 'Romania'">
            <div class="inp__row additional_block">
              <input type="text" :placeholder="$t('basket.date.city')" v-model="formData.additionally.city" :class="{'warning': !regexpAdditionally.city.status }">
              <input type="text" :placeholder="$t('basket.date.country')" v-model="formData.additionally.country"  :class="{'warning': !regexpAdditionally.country.status }">
            </div>
            <div class="inp__row additional_block">
              <input type="text" :placeholder="$t('basket.date.district')" v-model="formData.additionally.district" :class="{'warning': !regexpAdditionally.district.status }">
              <input type="text" :placeholder="$t('basket.date.street')" v-model="formData.address" :class="{'warning': !regexp.address.status }">
            </div>
            <div class="inp__row additional_block inp_three_row">
              <input type="text" :placeholder="$t('basket.date.houseNumber')" v-model="formData.additionally.houseNumber" :class="{'warning': !regexpAdditionally.houseNumber.status }">
              <input type="text" :placeholder="$t('basket.date.block')" v-model="formData.additionally.block" :class="{'warning': !regexpAdditionally.block.status}">
              <input type="text" :placeholder="$t('basket.date.flat')" v-model="formData.additionally.flat" :class="{'warning': !regexpAdditionally.flat.status }">
            </div>
            <div class="inp__row additional_block">
              <input type="text" :placeholder="$t('basket.date.email')" v-model="formData.email"  :class="{'warning': !regexp.email.status }">
              <select class="selection_mode" v-model="formData.additionally.companySelector" :class="{'warning': !regexpAdditionally.companySelector.status }">
                <option value="individual">{{$t('basket.date.person')}}</option>
                <option value="entity">{{$t('basket.date.company')}}</option>
              </select>
            </div>
            <div class="inp__row additional_block" v-if="formData.additionally.companySelector == 'individual'">
              <input class="address_invoice" type="text" :placeholder="$t('basket.date.addressInvoice')" v-model="formData.additionally.invoiceAddress"  :class="{'warning': !regexpIndividual.invoiceAddress.status }">
            </div>
            <template v-else>
              <div class="inp__row additional_block">
                <input type="text" :placeholder="$t('basket.date.companyName')" v-model="formData.additionally.companyName" :class="{'warning': !regexpCompany.companyName.status }">
                <input type="text" :placeholder="$t('basket.date.fiscalCode')" v-model="formData.additionally.companyCif" :class="{'warning': !regexpCompany.companyCif.status }">
              </div>
              <div class="inp__row additional_block">
                <input type="text" :placeholder="$t('basket.date.tradeNumber')" v-model="formData.additionally.companyRc" :class="{'warning': !regexpCompany.companyRc.status }">
                <input type="text" :placeholder="$t('basket.date.companyAddress')" v-model="formData.additionally.companyAddress" :class="{'warning': !regexpCompany.companyAddress.status }">
              </div>
            </template>
          </template>
          



          <template v-if="specialistLocation == 'Russian Federation'">
              <div class="inp__row additional_block">
                <input type="text" :placeholder="$t('basket.date.city')" v-model="formData.additionally.city" :class="{'warning': !regexpAdditionally.city.status }">
                <input type="text" :placeholder="$t('basket.date.country')" v-model="formData.additionally.country"  :class="{'warning': !regexpAdditionally.country.status }">
              </div>
              <div class="inp__row additional_block">
                <input type="text" :placeholder="$t('basket.date.postcode')" v-model="formData.addRu.postcode" :class="{'warning': !regexpAddRu.postcode.status }">
                <input type="text" :placeholder="$t('basket.date.street')" v-model="formData.address" :class="{'warning': !regexp.address.status }">
              </div>
              <div class="inp__row additional_block inp_three_row">
              <input type="text" :placeholder="$t('basket.date.houseNumber')" v-model="formData.additionally.houseNumber" :class="{'warning': !regexpAdditionally.houseNumber.status }">
              <input type="text" :placeholder="$t('basket.date.block')" v-model="formData.additionally.block" :class="{'warning': !regexpAdditionally.block.status}">
              <input type="text" :placeholder="$t('basket.date.flat')" v-model="formData.additionally.flat" :class="{'warning': !regexpAdditionally.flat.status }">
              </div>
            <div class="inp__row additional_block">
              <input type="text" :placeholder="$t('basket.date.email')" v-model="formData.email"  :class="{'warning': !regexp.email.status }">
              <select class="selection_mode" v-model="formData.additionally.companySelector" :class="{'warning': !regexpAdditionally.companySelector.status }">
                <option value="individual">{{$t('basket.date.person')}}</option>
                <option value="entity">{{$t('basket.date.company')}}</option>
              </select>
            </div>
            <div class="inp__row additional_block" v-if="formData.additionally.companySelector == 'individual'">
            </div>
            <template v-else>
              <div class="inp__row additional_block">
                <input type="text" :placeholder="$t('basket.date.companyName')" v-model="formData.additionally.companyName" :class="{'warning': !regexpCompany.companyName.status }">
                <input type="text" :placeholder="$t('basket.date.fiscalCode')" v-model="formData.additionally.companyCif" :class="{'warning': !regexpCompany.companyCif.status }">
              </div>
              <div class="inp__row additional_block">
                <input type="text" :placeholder="$t('basket.date.tradeNumber')" v-model="formData.additionally.companyRc" :class="{'warning': !regexpCompany.companyRc.status }">
                <input type="text" :placeholder="$t('basket.date.companyAddress')" v-model="formData.additionally.companyAddress" :class="{'warning': !regexpCompany.companyAddress.status }">
              </div>
            </template>
          </template>




        </div>

        <div class="card">
          <strong>2. {{ selectLang.delivery.title }}</strong>
          <div class="options">
            <div class="option margin" :class="{'active': formData.deliver}" @click="formData.deliver = true">
              <b>{{ selectLang.delivery.courier }}</b>
              <span>{{ specialistLocation == 'Romania' ? $t('basket.delivery.courierDescRo') : $t('basket.delivery.courierDesc') }}</span>
            </div>
            <div class="option" :class="{'active': !formData.deliver}" @click="formData.deliver = false">
              <b>{{ selectLang.delivery.shop }}</b>
              <span>{{ selectLang.delivery.shopDesc }}</span>
            </div>
          </div>
          
          
          <template v-if="specialistLocation == 'Moldova, Republic of'">
            <div class="info_message" v-show="formData.deliver">
              <!-- <img src="/img/novaposta.png" alt="novaposta" width="20px" height="20px" class="novaposta_img"> -->
               {{$t('basket.delivery.novaPosta')}}
              <span>{{$t('basket.delivery.info')}}</span>
            </div>
            <v-select class="custom_select" v-show="formData.deliver" v-model="deliveryType" :options="deliveryTypes" :clearable="false"></v-select>
            <v-select class="custom_select" v-model="formData.additionally.city" :options="cities" :clearable="false"></v-select>
            <v-select class="custom_select" v-show="deliveryType.code != 'courier' && formData.deliver" v-model="office" :options="offices" :clearable="false" :class="{'warning': !regexp.office.status }"></v-select>
            <div class="wrapp_address" v-show="deliveryType.code == 'courier' && formData.deliver">
              <div class="street">
                <v-select class="custom_select"
                  v-model="formData.address"
                  :placeholder="selectLang.delivery.address"
                  :class="{'warning': !regexp.address.status }"
                  :options="streets" 
                  :clearable="false"
                  
                >
                </v-select>
              </div>
            <input class="small_input" v-model="house" type="text" :placeholder="selectLang.street.houseNumber" :class="{'warning': !regexp.apartment.status }">
            <input class="small_input" v-model="apartment" type="text" :placeholder="selectLang.street.flat" :class="{'warning': !regexp.apartment.status }">
            </div>
            <input class="email_input" type="text" placeholder="Email"  v-model="formData.email" :class="{'warning': !regexp.email.status }">
          </template>
        </div>

        <div class="card" v-if="specialistLocation == 'Moldova, Republic of'" >
          <strong>3. {{ $t('basket.payment.title') }}</strong>
          <div class="payment__opt">
            <div class="option" :class="{'active' : formData.paymentByCard}" @click="formData.paymentByCard = true">
              {{$t('basket.payment.card')}}
            </div>
            <div class="option" :class="{'active' : !formData.paymentByCard}" @click="formData.paymentByCard = !formData.paymentByCard">
              <!-- {{$t('basket.payment.cash')}} -->
              {{$t('basket.payment.сash')}}
            </div>
          </div>
        </div>
        <div v-else class="card">
          <strong>3. {{ $t('basket.payment.title') }}</strong>
          <div class="payment__opt">
            <div class="option" :class="{'active' : formData.paymentByCard}" @click="formData.paymentByCard = true" style="width: 100%">
              {{$t('basket.payment.card')}}
            </div>
            </div>
          </div>

        <div class="card">
          <strong>{{ selectLang.confirmare.title }}</strong>
          <textarea :placeholder="selectLang.confirmare.comments" v-model="formData.desc"></textarea>
          <input 
            class="promo_code"
            type="text" 
            :style="{backgroundColor: arrayPromoProducts.length ? '#9ce69c69' : '#ffffff'}"
            v-if="searchPromoCodesThisProduct.length"
           :placeholder="selectLang.confirmare.promo"
            v-model="formData.promoCode"
          >
          <div class="delivery">
            <div class="delivery_ro cashback" v-if="cashback > 0">
              <label>
                <input type="checkbox" v-model="useCashback"/>
                <div class="checkbox" :class="{'active': useCashback}"></div>
              </label>
              <p>{{selectLang.useCashback}} <span class="cashback">({{cashback}} {{currency}})</span></p>
            </div>

            <div 
              class="delivery_ro" 
              v-if="specialistLocation == 'Romania'"
            >
              <label>
                  <input type="checkbox" v-model="formData.acceptRoDelivery"/>
                  <div class="checkbox" :class="{'active': formData.acceptRoDelivery, 'warning': !regexp.acceptRoDelivery.status}"></div>
              </label>
              <p>Pentru comenzile mai mari de 500 lei - transportul este gratuit. În caz contrar, plata transportului va fi suportată de client și calculată de Fan Courier (costul difera în funcție de localitate).</p>
            </div>
          </div>

          <template
            v-if="specialistLocation == 'Romania'"
          >
          <div class="total">
              {{ $t('basket.confirmare.orderAmount') }}:
              {{ totalSumRo }}
              {{ currency }}
            </div>
            <div class="total">
              {{ $t('basket.confirmare.total') }}:
              {{ generateTotalDeliveryCashbackPrice }}
              {{ currency }}
            </div>
          </template>

          <div
            v-else
           class="total" 
          >
            {{ selectLang.confirmare.total }}: 
            {{ generateTotalDeliveryCashbackPrice }}
            {{currency}}
          </div>



          <div class="finish__order">
            <div class="policy_wrapp">
              <label>
                  <input type="checkbox" v-model="formData.acceptPolitica2" :class="{'warning': !regexp.accept2.status }">
                  <span>
                    <a 
                      style="display:block; margin-top:6px"
                      :class="{'warning-text': !regexp.accept2.status }"
                      target="_blank" 
                      :href="checkCountryRu ? `/policy_ru/policy_ru.pdf` : !checkCounryRo ? `/policy_md/policy_${lang}.pdf` : '/policy_ro/policy_ro.pdf'"   

                    >
                    {{ selectLang.confirmare.policy }} 
                    </a>
                  </span>
              </label>
              <label>
                  <input type="checkbox" v-model="formData.acceptPolitica" :class="{'warning': !regexp.accept.status }">
                  <span>
                    <a 
                      style="display:block; margin-top:6px"
                      :class="{'warning-text': !regexp.accept.status }"
                      target="_blank" 
                      :href="checkCountryRu ? `/terms_ru/terms_ru.pdf` : !checkCounryRo ? `/reglament_md/reglament-${lang}.pdf` :  `/terms_ro/terms_ro.pdf`" 

                    >
                    {{ selectLang.confirmare.conditions }} 
                    </a>
                  </span>
              </label>
            </div>
            <button :disabled="btnBuy" type="button" @click="sendShop">
              <template v-if="!btnBuy">
              {{ selectLang.confirmare.finish }}
              </template>
             <template v-else>
              Loading                     
             </template>
            </button>
          </div>
        </div>
        <div v-show="false" ref="formWrapp"></div>
      </form>
    </div>
  </div>
  <FooterApp/>
</div>
</template>

<script>
import LangRu from '@/lang/Basket/ru' 
import LangRo from '@/lang/Basket/ro' 
import LangEng from '@/lang/Basket/eng'
import LangEs from '@/lang/Basket/es'

import SpecialistShopHeader from "@/components/SpecialistShopHeader"
import FooterApp from "@/components/FooterApp";
import { mapGetters, mapActions } from "vuex"
import { getCurrency, getProductClientPrice } from '@/utils/helpers';

const domain = process.env.VUE_APP_BASE_API;
const crypto = require('crypto');
const utf8 = require('utf8');

export default {
  mixins: [LangRu, LangRo, LangEng, LangEs],
  components: {
    SpecialistShopHeader,
    FooterApp
  },
  async beforeMount(){
    this.getPromoCodes();
    if (!Object.keys(this.localSpecialist).length) { 
      await this.setLocalSpecialist(this.userId)
    }
    if(this.cards.length) {
      this.specialist = await this.getUser(this.cards[0].userId);
      this.specialistLocation = this.specialist?.profile?.city;
      this.userId = this.cards[0].userId;
    }
    if(this.specialistLocation == 'Moldova, Republic of'){
      this.deliveryType = this.deliveryTypes[0];
      this.cities = await this.initCities();
  
      this.streets = await this.initStreets("5322e7e2-4042-11e5-80d5-00155d0f0cca");
      this.formData.address = this.streets[0];
  
      this.offices = await this.initOffices("5322e7e2-4042-11e5-80d5-00155d0f0cca");
      this.office = this.offices[0];
    }
  },
  data:() => ({
    formData: {
      name: '',
      surname: '',
      phone: '',
      address: '',
      email: '',
      desc: '',
      deliver: true,
      paymentByCard: true,
      acceptPolitica: false,
      acceptPolitica2: false,
      acceptRoDelivery: false,
      addRu: {
        postcode: ''
      },
      additionally: {
        city: {
          label: "Chisinau",
          ref: "5322e7e2-4042-11e5-80d5-00155d0f0cca",
          description:"Chisinau",
          descriptionRo:"Chișinău",
          descriptionRu:"Кишинёв",
        },
        country: '',
        district: '',
        houseNumber: '',
        flat: '',
        companySelector: 'individual',
        invoiceAddress: '',
        companyName: '',
        companyCif: '',
        companyRc: '',
        companyAddress: '',
        block: '',
      },
      promoCode: ''
    },
    btnBuy: false,
    regexp: {
      name: {
        status: ' '
      },
      surname: {
        status: ' '
      },
      phone: {
        status: ' '
      },
      address: {
        status: ' '
      },
      email: {
        status: ' '
      },
      accept: {
        status: ' '
      },
      accept2: {
        status: ' '
      },
      acceptRoDelivery: {
        status: ' '
      },
      notEmty: {
        status: ' '
      },
      office: {
        status: ' '
      },
      apartment: {
        status: ' '
      }
    },
    regexpAddRu:{
      postcode:{
        status: ' '
      }
    },
    regexpAdditionally: {
      city: {
        status: ' '
      },
      block:{
        status: ' '
      },
      country: {
        status: ' '
      },
      district: {
        status: ' '
      },
      houseNumber: {
        status: ' '
      },
      flat: {
        status: ' '
      },
      companySelector: {
        status: ' '
      },
    },
    regexpIndividual: {
      invoiceAddress: {
        status: ' '
      },
    },
    regexpCompany: {
      companyName: {
        status: ' '
      },
      companyCif: {
        status: ' '
      },
      companyRc: {
        status: ' '
      },
      companyAddress: {
        status: ' '
      }
    },
    order_id: '',
    responcePromoCodes: [],
    productPromoCode: [],
    arrayPromoProducts: [],
    invoice_id: '',
    cashback: 0,
    useCashback: false,
    deliveryType: { label: "", code: "courier" },
    cities: [],
    city: '',
    offices: [],
    office: "",
    streets: [],
    house: "",
    apartment: "",
    specialistLocation: "Moldova, Republic of",
    specialist: null,
    userId: null
  }),
  async created() {
    await this.checkProductsAreAvailable()
    await this.checkProducts();
  },
  computed: {
    ...mapGetters({
      products: "checkout/products",
      allProducts: 'shopCards/getAll',
      cards: 'checkout/productsDetailed',
      oldTotalSum: 'checkout/totalSum',
      oldTotalSumRo: 'checkout/totalSumRo',
      lang: 'languages/getLang',
      user: 'user/user',
      localSpecialist: 'specialists/localSpecialist',
    }),
    totalSum(){
      let price = Number(this.productStore.reduce((total, pr) => {
        return total + pr.price * pr.cnt;
      }, 0).toFixed(1));
     return price;
    },
    totalSumRo(){
      let price = Number(this.productStore.reduce((total, pr) => {
        return total + pr.price_ro * pr.cnt;
      }, 0).toFixed(1) );
      return price;
    },
    totalSumRu(){
      let price = Number(this.productStore.reduce((total, pr) =>{
        return total + pr.price_ru * pr.cnt
      }, 0).toFixed(1) );
      return price
    },
    deliveryPrice(){
      if(this.formData.deliver == false){
        return 0;
      }
      else {
        return (this.specialistLocation == 'Romania') ? 0 : (this.totalSum >= 1000 ? 0 : 0);
      }
    },
    wrappOrder(){
      return this.productStore.map((item) => ({
        'quantity': item.cnt,
        'unit_price': String(item[this.productCurrency]),
        'discount': item.discount ? item.discount : 0,
        'total_price': String(item[this.productCurrency] * item.cnt),
        'product_id': item.id,
        // 'promo_code': item.code ? item.code : '',
        // 'promo_discount': item.code ? String((item[this.PromoProductCurrency] * item.cnt) - (item[this.productCurrency] * item.cnt)) : ''
      }));
    },
    selectLang() {
      if(this.lang == 'ro'){
        return this.langRo;
      }
      else if(this.lang == 'ru'){
        return this.langRu;
      }
      else if(this.lang == 'es'){
        return this.langEs;
      }
      return this.langEng;
    },
    productCurrency(){
      if(this.specialistLocation == "Romania"){
        return 'price_ro';
      }
      else if(this.specialistLocation == "Russian Federation") {
        return 'price_ru';
      }
      else{
        return 'price'
      }
    },
    PromoProductCurrency(){
      if(this.specialistLocation == "Romania"){ 
        return 'price_old_ro';
      }
      else {
        return 'price_old';
      }
    },
    currency(){
      if(this.specialistLocation == "Romania"){
        return "RON";
      }
      else if(this.specialistLocation == 'Moldova, Republic of'){
        return "MDL"
      }
      else{
        return "RUB"
      }
    },
    currencyPrice(){
      if(this.specialistLocation == "Romania"){
       return this.totalSumRo;
      }
      else if(this.specialistLocation == 'Moldova, Republic of'){
        return this.totalSum
      }
      else{
        return this.totalSumRu
      }
    },
    checkCounryRo(){
      return this.specialistLocation == 'Romania';
    },
    checkCountryRu(){
      return this.specialistLocation == 'Russian Federation'
    },
    searchPromoCodesThisProduct() {
      let array = [];
      let d = new Date();
      let m = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
      let day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
      let formatDate = new Date(`${d.getFullYear()}-${m}-${day}`);
      this.responcePromoCodes.forEach(el => {
        if(el.is_active && new Date(el.date_start).getTime() <= formatDate.getTime() && new Date(el.date_end).getTime() >= formatDate.getTime()){
          el.promo_code_prod.forEach(prod => {
            this.cards.forEach(basketProd => {
              if(basketProd.id == prod.product) {
                array.push({
                  idProduct: prod.product,
                  code: el.code,
                  discount: el.discount
                });
              }
            });
          });
        }
      });
      return array;
    },
    productStore() {
      let ar1 = [];
      let arrId = [];

      this.arrayPromoProducts.forEach(prod => {
        for(let storagePr of this.cards) {
          if(storagePr.id == prod.idProduct){
            let o = {
              ...storagePr,
              ...prod,
              old_price: storagePr.price,
              old_price_ro: storagePr.price_ro,
              old_price_ru: storagePr.price_ru,
              discount: prod.discount
            }
            o.price = Math.trunc(storagePr.price - (storagePr.price / 100 * prod.discount)),
            o.price_ro = Math.trunc(storagePr.price - (storagePr.price / 100 * prod.discount)),
            o.price_ru = Math.trunc(storagePr.price -(storagePr.price / 100 * prod.discount))
            ar1.push(o);
            arrId.push(o.id);
          }
        }
      });
      
      this.cards.forEach(el => {
        if(arrId.includes(el.id) == false) {
          arrId.push(el.id);
          ar1.push(el);
        }
      });

     return ar1;
    },
    paymentSystem(){
      if(this.checkCounryRo){
        return "https://secure.euplatesc.ro/tdsprocess/tranzactd.php"
      }
      else if(this.checkCountryRu){
        return  this.prodamusLink
      }
      else{
      return "https://paynet.md/acquiring/setecom"
      }
    },
    generateTotalDeliveryCashbackPrice(){
      let value = 0
      if(this.checkCounryRo){
         value = this.totalSumRo
      }
      else if(this.checkCountryRu){
         value = this.totalSumRu
      }
      else{
         value = this.totalSum
      }
      value += this.deliveryPrice;
      return this.useCashback ? (value - this.cashback) < 0 ? 0 : (value - this.cashback) : value;
    },
    deliveryTypes(){
      return [
        {
          label: this.selectLang.delivery.selectType.courier,
          code: "courier"
        }
      ];
    },
    parcelWeight(){
      return this.productStore.reduce((accumulator, el) => {
        let onlyNumber = el.weight_packaging.replace(/\D+/, '');
        let number = onlyNumber == "" ? 0 : onlyNumber;
        return accumulator + parseFloat(number) * el.cnt;
      }, 0);
    },
    countryCode(){
      if(this.localSpecialist == "Romania"){
        return "ro"
      }
      else if(this.localSpecialist == "Russian Federation"){
        return "ru"
      }
      else{
        return "md"
      }
    },
  },
  watch:{
    'formData.promoCode': function(val, old) {
      this.productPromoCode = this.checkPromo(val);
    },
    'formData.additionally.city': async function (el) {
      if(this.deliveryType.code == "courier"){
        this.streets = await this.initStreets(el.ref);
        if(this.streets.length == 0){
          this.formData.address = "";
        }
        else {
          this.formData.address = this.streets[0];
        }
      }
      else {
        this.offices = await this.initOffices(el.ref);
        if(this.offices.length == 0){
          this.office = "";
        }
        else {
          this.office = this.offices[0];
        }
      }
    },
  },
  methods: {
    ...mapActions('checkout', {
      setCnt: 'setCnt',
      remove: 'remove' 
    }),
    ...mapActions('specialists', ['setLocalSpecialist']),
    async sendShop(){
      if(this.validator()){
        this.specialistLocation = "Moldova, Republic of";
        let d = new Date();
        this.btnBuy = true;
        this.order_id = `0000${d.getTime()}`;
        let external_id_duble = d.getTime();
        this.invoice_id = `${this.getRandomInt()}${this.getRandomInt()}${this.getRandomInt()}${this.getRandomInt()}${this.getRandomInt()}${this.getRandomInt()}`;
        this.order_id = `0000${d.getTime()}`;
        let postData = this.specialistLocation == 'Romania' ? {
          'user_id': this.userId,
          'name': this.formData.name,
          'surname': this.formData.surname,
          'delivery_type': this.formData.deliver ? 'courier' : 'pickup',
          'pay_method': this.formData.paymentByCard ? 'card': 'cash',
          'currency': this.currency,
          'comment': this.formData.desc,
          'phone': String(this.formData.phone),
          'total_price': String(this.generateTotalDeliveryCashbackPrice),
          'status': this.generateTotalDeliveryCashbackPrice == 0 ? 'paid' : "new",
          'order_from_client': this.wrappOrder,
          'city':  this.formData?.additionally?.city?.description,
          'country': this.formData.additionally.country,
          'district': this.formData.additionally.district,
          'street': this.formData.address,
          'house_number': this.formData.additionally.houseNumber,
          'flat_number': this.formData.additionally.flat,
          'email': this.formData.email,
          'client_types': this.formData.additionally.companySelector,
          'invoice_address': this.formData.additionally.invoiceAddress,
          'company_name': this.formData.additionally.companyName,
          'company_cif': this.formData.additionally.companyCif,
          'company_rc': this.formData.additionally.companyRc,
          'company_address': this.formData.additionally.companyAddress,
          'external_id': this.checkCounryRo ? this.invoice_id : external_id_duble,
          'lang': this.lang, 
          'total_weight_packaging': this.parcelWeight,
          'bloc': this.formData.additionally.block,
          "utm_tags": this.$store.getters['utmQuery/strUtm']        
        } : this.specialistLocation == 'Moldova, Republic of' ?
        {
          'user_id': this.userId,
          'name': this.formData.name,
          'surname': this.formData.surname,
          'delivery_type': this.formData.deliver ? (this.deliveryType.code == "courier" ? 'courier_postman' : 'warehouse') : 'pickup',
          'pay_method': this.formData.paymentByCard ? 'card': 'cash',
          'currency': this.currency,
          'comment': this.formData.desc,
          'phone': String(this.formData.phone),
          'total_price': String(this.generateTotalDeliveryCashbackPrice),
          'status': this.generateTotalDeliveryCashbackPrice == 0 ? 'paid' : "new",
          'order_from_client': this.wrappOrder,
          'house_number': this.house,
          'flat_number': this.apartment,
          'street': this.formData.deliver && this.deliveryType.code == "courier" ? this.formData.address.description : '',
          'email': this.formData.email,
          'external_id': this.checkCounryRo ? this.invoice_id : external_id_duble,
          'lang': this.lang,
          'nova_poshta': this.formData.deliver,
          'city':  this.formData?.additionally?.city?.description,
          'city_ref': this.formData.additionally.ref,
          'street_ref': this.formData.address.ref,
          'warehouse_ref': this.office.ref,
          'total_weight_packaging': this.parcelWeight,
          "utm_tags": this.$store.getters['utmQuery/strUtm']
        }:
        {
          'user_id': this.userId,
          'name': this.formData.name,
          'surname': this.formData.surname,
          'delivery_type': this.formData.deliver ? 'courier' : 'pickup',
          'pay_method': this.formData.paymentByCard ? 'card': 'cash',
          'currency': this.currency,
          'comment': this.formData.desc,
          'phone': String(this.formData.phone),
          'total_price': String(this.generateTotalDeliveryCashbackPrice),
          'status': this.generateTotalDeliveryCashbackPrice == 0 ? 'paid' : "new",
          'order_from_client': this.wrappOrder,
          'city':  this.formData?.additionally?.city?.description,
          'country': this.formData.additionally.country,
          'postcode': this.formData.addRu.postcode,
          'street': this.formData.address,
          'house_number': this.formData.additionally.houseNumber,
          'flat_number': this.formData.additionally.flat,
          'email': this.formData.email,
          'client_types': this.formData.additionally.companySelector,
          'company_name': this.formData.additionally.companyName,
          'company_cif': this.formData.additionally.companyCif,
          'company_rc': this.formData.additionally.companyRc,
          'company_address': this.formData.additionally.companyAddress,
          'external_id': this.order_id,
          'lang': this.lang,
          // 'total_discount': "",
          'total_weight_packaging': this.parcelWeight,
          "utm_tags": this.$store.getters['utmQuery/strUtm']
        }
        
        ;
        try {
          let request = await fetch(`${domain}/shop/orders-from-client/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
          });

          if(!request.ok){
           throw request;
          }

          if(this.formData.paymentByCard && this.generateTotalDeliveryCashbackPrice != 0) {
            if(this.checkCounryRo){
              await this.platescRoSystem()
            }
            else if(this.checkCountryRu){
              await this.prodamusSystem(this.order_id)
            }
            else{
              await this.paynetSystem(external_id_duble)
            }
          }
          else {
            let req2 = ''
            if(this.checkCounryRo){
              req2 = await this.PaymentHistory(false, this.invoice_id)
            }
            else if(this.checkCountryRu){
              req2 =  await this.PaymentHistory(false, this.order_id)
            }
            else{
              req2 = await this.PaymentHistory(false, external_id_duble)
            }
           if(!req2.ok){
            throw req2;
           }
            this.btnBuy = false;
            this.$router.push({name: 'checkout-success'});
            this.sendPurchaseEvent({
              items: this.productStore.map(p => ({
                title: p.title,
                price: p[getProductClientPrice(this.localSpecialist?.city)],
                quantity: p.cnt,
              })),
              currency: getCurrency(this.localSpecialist?.city),
              user: {
                email: this.formData.email,
                phone: this.formData.phone,
                firstName: this.formData.name,
                lastName: this.formData.surname
              },
            })
          }
        }
        catch(err) {
          console.error(err);
        }

      }
      else {
        this.$scrollTo({el: 'header'});
      }
    },
    async PaymentHistory(buyCard , id){
      try {
        let request = await fetch(`${domain}/accounts/client-payment-history/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            user: this.userId,
            title: `Shop #${id}`,
            descriptions: "shop order",
            payment_code: id,
            amount: `${this.generateTotalDeliveryCashbackPrice} ${this.currency}`,
            confirmed: false,
            paid_by_card: buyCard,
            country: this.country, 
            type: 'shop',
            payment_system_name: buyCard ? (this.checkCounryRo ? 'euplatesc' : this.checkCountryRu ? 'prodamus' : 'paynet') : '',
            country: `${this.user.country?.country} / ${this.user.country?.city} - ip address`,
            "utm_tags": this.$store.getters['utmQuery/strUtm']
          })
        });
        return request;
      }
      catch(e){
        console.error(e);
      }
    },
    async prodamusSystem(){
      let customer_phone = this.formData.phone;
      let customer_email = this.formData.email;
      let linktoform = 'https://lica-doctor.payform.ru/'
      let data = {
          'customer_phone': customer_phone,
          'customer_email': customer_email,
          'do': 'pay',
          'urlSuccess': `https://lica.doctor/${this.lang}/checkout-success/`,
          'order_id': this.order_id,
          'urlNotification': `${domain}/courses/access_list/payment_notification_prodamus/`,
          'sys': ''
      }
      const array = Object.values(this.productStore)
      console.log(array)
          let string = ""
          array.forEach((el, index) => {
                string += `products[${index}][price]=${el.price_ru}&products[${index}][quantity]=${el.cnt}&products[${index}][name]=${el.title}&`;  
                string = string.replace(/\s+/g, '')
    });
      const params = new URLSearchParams({'customer_phone': data.customer_phone, 'customer_email' : data.customer_email, 'do': data.do, 'urlSuccess': data.urlSuccess, 'order_id': data.order_id, 'urlNotification': data.urlNotification, 'sys': data.sys})
      this.prodamusLink = linktoform+'?'+params+'&'+string
        let req3 = await this.PaymentHistory(true, this.order_id);
      if(!req3.ok) {
        throw req3;
      }
      // this.$refs.formByCourse.submit();
      window.open(this.prodamusLink, "_self").focus()
    },
    async platescRoSystem(){
      function addInput(tag, name, value) {
        let elem = document.createElement(tag);
        elem.setAttribute("name", name);
        elem.setAttribute("value", value);
        elem.setAttribute("type", "hidden");
        return elem;
      }

      let d = new Date();
      let amount = String(this.generateTotalDeliveryCashbackPrice);
      let curr = "RON";
      let invoice_id = this.invoice_id; //Order number
      let order_desc = "Shop lica doctor";
      let merch_id = "44840995506"; //ID Comerciant (MID):   44840995506 // fake testaccount
      let timestamp = `${d.getFullYear()}${d.getMonth()}${d.getDate()}${d.getMinutes()}${d.getSeconds()}`;
      let nonce = crypto.randomBytes(16).toString('base64');
      let hmac = '';

      let data = {
        "amount": amount,
        "curr": curr,
        "invoice_id": invoice_id,
        "order_desc": order_desc,
        "merch_id": merch_id,
        "timestamp":timestamp,
        "nonce": nonce,
      };

      let datakeys = Object.keys(data);

      
      for(let i = 0; i < datakeys.length; i++){
        if(data[datakeys[i]].length == 0) {
          hmac += '-';
        } else {
          hmac += data[datakeys[i]].length + data[datakeys[i]];
        }
      }

      let binKey = new Buffer("5C335286A4FF60888AF0184CADCA6024A3902F9C", "hex"); //key Cheie  5C335286A4FF60888AF0184CADCA6024A3902F9C  // fake 00112233445566778899AABBCCDDEEFF
      let hmacx = crypto.createHmac("md5", binKey).update(hmac, 'utf8').digest('hex');
      data["fp_hash"] = hmacx;


      this.$refs.formWrapp.appendChild(addInput("input", "amount", amount));
      this.$refs.formWrapp.appendChild(addInput("input", "curr", curr));
      this.$refs.formWrapp.appendChild(addInput("input", "invoice_id", invoice_id));
      this.$refs.formWrapp.appendChild(addInput("input", "order_desc", order_desc));
      this.$refs.formWrapp.appendChild(addInput("input", "merch_id", merch_id));
      this.$refs.formWrapp.appendChild(addInput("input", "timestamp", timestamp));
      this.$refs.formWrapp.appendChild(addInput("input", "nonce", nonce));
      this.$refs.formWrapp.appendChild(addInput("input", "fp_hash", hmacx));
      this.$refs.formWrapp.appendChild(addInput("input", "ExtraData[silenturl]", `${domain}/courses/access_list/payment_notification_euplatesc/`)); //запрос POST от сервера к серверу)
      this.$refs.formWrapp.appendChild(addInput("input", "ExtraData[successurl]", `https://lica.doctor/${this.lang}/checkout-success/`));
      this.$refs.formWrapp.appendChild(addInput("input", "ExtraData[failedurl]", `https://lica.doctor/${this.lang}/`));
      this.$refs.formWrapp.appendChild(addInput("input", "ExtraData[backtosite]", `https://lica.doctor/${this.lang}/`));

      this.$refs.formWrapp.appendChild(addInput("input", "fname", this.formData.name));
      this.$refs.formWrapp.appendChild(addInput("input", "phone", this.formData.phone));
      this.$refs.formWrapp.appendChild(addInput("input", "email", this.formData.email));

      let req2 = await this.PaymentHistory(true, this.invoice_id);
      if(!req2.ok) {
        throw req2;
      }
      this.$refs.formByCourse.submit();
    },
    async paynetSystem(externalId){
      function addInput(tag, name, value) {
        let elem = document.createElement(tag);
        elem.setAttribute("name", name);
        elem.setAttribute("value", value);
        elem.setAttribute("type", "hidden");
        return elem;
      }

      let sicretKey = 'F0321E5A-876C-4AE5-933A-061FC44FBBF3'; // test '47DB687B-6C10-40C1-8522-C9B404F6D71D' //real F0321E5A-876C-4AE5-933A-061FC44FBBF3
      let date = new Date(); // toISOString()
      let month = date.getMonth() + 1;
      let dd = date.getDate();
      let mm = date.getMinutes();
      // let hh = date.getHours() + 1;
      let hh = 23;

      let dateFormat = `${date.getFullYear()}-${month >= 10 ? month : '0' + month }-${dd >= 10 ? dd : '0' + dd}T${hh >= 10 ? hh : '0' + hh}:${mm >= 10 ? mm : '0' + mm }:00`;
  
    
      let expire_date = dateFormat; // date format 2021-03-08T12:00:00
      let external_id = externalId; //date.getTime()
    
      let customer_name = this.formData.name;
      let customer_phone = this.formData.phone;
      let customer_code =  "12345678-" + "0" + "-" + this.userId;
      let merchant = '446466'; // test 631502 //real 446466
      let ammount = (this.generateTotalDeliveryCashbackPrice) * 100;
      let currency = this.currency == 'MDL' ? '498' : '978';  //498 MDL, 978 EUR, 840 $
      let pay_method = 'lica.doctor';
      let pay_desc = 'оплата через Paynet';
      let prep_string = currency + customer_code + customer_name + expire_date + external_id + merchant + ammount + pay_method + pay_desc + sicretKey;

    
      const signature = crypto.createHash('md5').update(utf8.encode(prep_string), "binary").digest('base64');

      this.$refs.formWrapp.appendChild(addInput("input", "ExternalID", external_id));
      this.$refs.formWrapp.appendChild(addInput("input", "Currency", currency));
      this.$refs.formWrapp.appendChild(addInput("input", "Merchant", merchant));
      this.$refs.formWrapp.appendChild(addInput("input", "Customer[Code]", customer_code));
      this.$refs.formWrapp.appendChild(addInput("input", "Customer[Name]", customer_name));
      this.$refs.formWrapp.appendChild(addInput("input", "Customer.Address", ""));
      this.$refs.formWrapp.appendChild(addInput("input", "Customer[NameFirst]", customer_name));
      this.$refs.formWrapp.appendChild(addInput("input", "Customer.PhoneNumber", customer_phone));
      this.$refs.formWrapp.appendChild(addInput("input", "Customer.Country.", "Moldova"));
      this.$refs.formWrapp.appendChild(addInput("input", "Customer.City", "Chisinau"));
      this.$refs.formWrapp.appendChild(addInput("input", "ExpiryDate", expire_date));
      this.$refs.formWrapp.appendChild(addInput("input", "LinkUrlSuccess", "http://lica.doctor/checkout-success"));
      this.$refs.formWrapp.appendChild(addInput("input", "LinkUrlCancel", "http://lica.doctor/"));
      this.$refs.formWrapp.appendChild(addInput("input", "Lang", this.lang));
      this.$refs.formWrapp.appendChild(addInput("input", "SaleAreaCode", 'http_lica_doctor'));
      // this.$refs.formWrapp.appendChild(addInput("input", "SignVersion", 'v05'));
    

      this.$refs.formWrapp.appendChild(addInput("input", "Services[0][Name]", pay_method));
      this.$refs.formWrapp.appendChild(addInput("input", "Services[0][Amount]", ammount));
      this.$refs.formWrapp.appendChild(addInput("input", "Services[0][Description]", pay_desc));
      this.$refs.formWrapp.appendChild(addInput("input", "Services[0][Products][1][GroupId]", "0"));

      this.$refs.formWrapp.appendChild(addInput("input", "Signature", signature));
      let req1 = await this.PaymentHistory(true, external_id);
      if(!req1.ok) {
        throw req1;
      }
      this.$refs.formByCourse.submit();
    },
    validator() {
      this.regexp.name.status = this.formData.name.length >= 4;
      this.regexp.surname.status = this.formData.surname.length >= 2;
      this.regexp.phone.status = this.formData.phone.length >= 8;
      this.regexp.email.status = this.formData.email.length >= 5;
      this.regexp.accept.status = this.formData.acceptPolitica;
      this.regexp.accept2.status = this.formData.acceptPolitica2;

      if(this.formData.deliver){
        this.regexp.address.status = this.formData.address != "";
      }
      
      this.regexp.acceptRoDelivery.status = this.specialistLocation != 'Romania' ? true : this.formData.acceptRoDelivery;
      
      
     
      this.regexp.notEmty.status = this.cards.length != 0;

      // Nova Poshta
      if(this.specialistLocation == 'Moldova, Republic of' && this.formData.deliver){
        if(this.deliveryType.code == "courier"){
          this.regexp.apartment.status = this.house != "";
        }
        else {
          this.regexp.office.status = this.office != "";
        }
      }


      for (let key in this.regexp) {
        if(this.regexp[key].status === false){
          return false;
        }
      }
      if(this.specialistLocation == 'Russian Federation'){
        this.regexpAdditionally.city.status = this.formData.additionally.city.length >= 4;
        this.regexpAdditionally.country.status = this.formData.additionally.country.length >= 4;
        this.regexpAddRu.postcode.status = this.formData.addRu.postcode.length >= 2;
        this.regexpAdditionally.companySelector.status = this.formData.additionally.companySelector.length >= 1;
        this.regexpAdditionally.houseNumber.status = this.formData.additionally.houseNumber.length >= 1;
        this.regexpAdditionally.flat.status = this.formData.additionally.flat.length >= 1;
        this.regexpAdditionally.block.status = this.formData.additionally.block.length >= 1;

        this.regexpCompany.companyName.status = this.formData.additionally.companyName.length >= 3;
        this.regexpCompany.companyCif.status = this.formData.additionally.companyCif.length >= 1;
        this.regexpCompany.companyRc.status = this.formData.additionally.companyRc.length >= 1;
        this.regexpCompany.companyAddress.status = this.formData.additionally.companyAddress.length >= 3;
        
        if(this.formData.addRu.postcode.length < 2){
          return false
        }
        if(this.formData.additionally.companySelector !== 'individual'){
          for (let key in this.regexpCompany) {
            if(this.regexpCompany[key].status == false){
              return false;
            }
          }
        }
      }
      if(this.specialistLocation == 'Romania') {
        this.regexpAdditionally.city.status = this.formData.additionally.city.length >= 4;
        this.regexpAdditionally.country.status = this.formData.additionally.country.length >= 4;
        this.regexpAdditionally.district.status = this.formData.additionally.district.length >= 4;
        this.regexpAdditionally.houseNumber.status = this.formData.additionally.houseNumber.length >= 1;
        this.regexpAdditionally.flat.status = this.formData.additionally.flat.length >= 1;
        this.regexpAdditionally.companySelector.status = this.formData.additionally.companySelector.length >= 1;
        this.regexpAdditionally.block.status = this.formData.additionally.block.length >= 1;

        this.regexpIndividual.invoiceAddress.status = this.formData.additionally.invoiceAddress.length >= 3;

        this.regexpCompany.companyName.status = this.formData.additionally.companyName.length >= 3;
        this.regexpCompany.companyCif.status = this.formData.additionally.companyCif.length >= 1;
        this.regexpCompany.companyRc.status = this.formData.additionally.companyRc.length >= 1;
        this.regexpCompany.companyAddress.status = this.formData.additionally.companyAddress.length >= 3;

        for (let key in this.regexpAdditionally) {
          if(this.regexpAdditionally[key].status == false){
            return false;
          }
        }

        if(this.formData.additionally.companySelector == 'individual'){
          for (let key in this.regexpIndividual) {
            if(this.regexpIndividual[key].status == false){
              return false;
            }
          }
        }
        else {
          for (let key in this.regexpCompany) {
            if(this.regexpCompany[key].status == false){
              return false;
            }
          }
        }
      }

      return true;
    },
        async getPromoCodes() {
      try {
        let request = await fetch(`${domain}/shop/promo-code-product/`, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if(request.ok){
          let responce = await request.json();
          this.responcePromoCodes = responce;
        }
        else {
          console.error(request);
        }
      }
      catch(er) {
        console.error(er);
      }
    },
    checkPromo(promo) {
     this.arrayPromoProducts = [];
      for(let obj of this.searchPromoCodesThisProduct) {
        if(obj.code == promo) {
          this.arrayPromoProducts.push(obj);
        }
      }
    },
    getRandomInt() {
      let min = Math.ceil(1);
      let max = Math.floor(9);
      return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
    },
    async getNovapostaCities(){
      try {
        const request = await fetch(`${domain}/ru/nova-poshta-get-city/`, {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
          }
        });
        if(!request.ok){
          throw request;
        }
        let {data} = await request.json();
        return data;
      }
      catch(er){
        console.error(er);
      }
    },
    async initCities(){
      let cities = await this.getNovapostaCities();
      let uniquSet = [];
      for(let i = 0; i < cities.length; i++) {
        if(uniquSet[uniquSet.length - 3]?.id != cities[i].id || uniquSet.length == 0 ){
          let en = {
            ...cities[i],
            label: cities[i].description,
          }
          let ru = {
            ...cities[i],
            label: cities[i].descriptionRu,
            id: cities[i].ref + i
          }
          let ro = {
            ...cities[i],
            label: cities[i].descriptionRo,
            id: cities[i].ref + i + cities[i].id
          }
          uniquSet.push(en, ru, ro);
        }
      }
      return uniquSet
    },
    async getOffices(ref){
      try {
        const request = await fetch(`${domain}/ru/nova-poshta-get-city/?warehouse=true&city=${ref}`);
        if(!request.ok){
          throw request;
        }
        let {data} = await request.json();
        return data;
      }
      catch(ex) {
        console.error(ex);
      }
    },
    async initOffices(ref){
      let data = [];
      let offices = await this.getOffices(ref);
      offices.forEach(el => {
        data.push({
          ...el,
          label: el.address.description
        });
      });
      return data;
    },
    async getStreets(ref){
      try {
        const request = await fetch(`${domain}/ru/nova-poshta-get-city/?street=true&city=${ref}`);
        if(!request.ok){
          throw request;
        }
        let {data} = await request.json();
        return data;
      }
      catch(ex) {
        console.error(ex);
      }
    },
    async initStreets(ref){
      let streets = await this.getStreets(ref);
      let data = [];
      streets.forEach(el => {
        if(el.streetType != null){
          data.push({
            ...el,
            label: el.description
          });
        }
      });
      return data;
    },
    async getUser(id){
      try {
        let request = await fetch(`${domain}/accounts/users/${id}/`);
        if(!request.ok){
          throw request;
        }
        let res = await request.json();
        return res;
      }
      catch(ex) {
        console.error(ex);
        this.$router.push({name: "Home"});
      }
    },
    async checkProducts() {
      await this.setProducts(this.countryCode);
      const removePr = [];
      this.products.forEach(pr => {
        const product = this.allProducts.results.find(e => e.id == pr.id);
        if(product) {
          if(
            !product.active || 
            !product[`is_active_for_${this.countryCode}`] || 
            !product[`in_stock_for_${this.countryCode}`] 
            ){
            removePr.push(pr.id);
            this.remove({ id:pr.id });
          }
        }
      });
      return removePr
    },
    ...mapActions({
      setProducts: "shopCards/setSpecialistProducts",
      checkProductsAreAvailable: 'checkout/checkProductsAreAvailable',
      sendPurchaseEvent: 'googleEvents/sendPurchaseEvent'
    })

  }
}
</script>

<style lang="scss">
  //  .checkout__row form .card .inp_three_row input[type=text]{
  //   width: calc(33% - 12px) ;
  // }
  .checkout__row  {
    .products__col .card  {
      .img__cont {
        max-width: 140px;
      }
      .details .btm {
        @media(max-width: 430px){
          margin-top: 15px;
        }
      }
    }
    .card {
      align-items: center !important;
    }
  }


  .checkout__wrp .warning {
    border: 1px solid rgba(255, 0, 0, 0.493) !important;
    background-color: rgba(255, 0, 0, 0.034) !important;
    &::after {
      border: 1px solid rgba(255, 0, 0, 0.493) !important;
      background-color: rgba(255, 0, 0, 0.034) !important;
    }
  }
  .checkout__wrp .warning-text {
    color: red !important;
  }
 
  .checkout__wrp {
    .additional_block {
      margin-top: 20px;

      .selection_mode {
        width: calc(50% - 12px);
        @media(max-width: 430px){
          width: 100%;
        }
        outline: none;
        background: #FFF;
        border-radius: 6px;
        padding: 0 22px;
        height: 48px;
        font-size: 16px;
        line-height: 24px;
        color: #000001;
        border: none;
        cursor: pointer;
      }
      .address_invoice {
        width: 100% !important;
      }
    }
  }

  .checkout__wrp {
    .policy_wrapp {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 60px;
      width: calc(54.57% - 36px);
      margin-right: 36px;
      @media(max-width: 430px){
        width: 100%;
        height: 95px;
      }
      label {
        width: 100% !important;
        margin-right: 0 !important;
        input  {
          &:not(:checked)::after {
            background: #930f81b4 !important;
          }
          &.warning:not(:checked)::after {
            background: rgba(255, 0, 0, 0.034) !important;
          }
        }
      }
    }
    .card {
      .email_input {
        margin-top: 20px;
      }
    }
  }

  .checkout__wrp {
    form .card textarea {
      margin-bottom: 20px;
    }
    .promo_code {
      margin-bottom: 70px;
    }
  }

  .checkout__wrp {
    .card {
      .details {
        width: 100%;
        .btm {
          width: 100%;
          justify-content: space-between;
        }
      }
    }
    .red_text {
      color: red;
    }
  }

  .checkout__wrp {
    .delivery {
      .delivery_ro {
        display: flex;
        align-items: center;
        label {
          position: relative;
          input {
            position: absolute;
            width: 24px;
            height: 24px;
            opacity: 0;
            cursor: pointer;
          }
          .checkbox {
            width: 24px;
            height: 24px;
            border-radius: 3px;
            background-color: #930f81b4;
            z-index: -1;
            &.active {
              background-color: transparent;
              background-image: url("/img/checkout-check.svg");
            }
          }

        }

        p {
          padding-left: 20px;
          font-size: 14px;
        }
      }
    }
  }
   .checkout__wrp{
   margin-top: 128px;
 }
  .checkout__wrp {
    .delivery {
      width: 100%;
    }
    .delivery_ro.cashback {
      margin-bottom: 25px;
    }
    span.cashback {
      font-size: 13px;
      color: #069348;
    }
  }

  .checkout__wrp {
    .custom_select {
      width: 100%;
      background: #FFF;
      border-radius: 6px;
     
      margin-top: 20px;
      font-size: 16px;
      line-height: 24px;
     
     
      .vs__dropdown-toggle {
        padding-left: 18px;
        height: 48px;
        
        border: none;
        .vs__selected {
          font-weight: 400;
          color: #000001;
        }
      }
    }
    .novaposta_img {
      margin-right: 10px;
    }
    .info_message {
      display: flex;
      align-items: center;
      align-self: flex-start;
      color: #909090;
      position: relative;
      cursor: pointer;
      span {
        position: absolute;
        top: calc(100% + 6px);
        right: 0;
        opacity: 0;
        text-align: left;
        pointer-events: none;
        padding: 10px 16px 15px;
        background-color: #f2f2f2;
        border-top: 1px solid #ababab;
        box-shadow: 0 10px 10px rgb(0 0 0 / 7%);
        transition: .2s ease;
        z-index: 2;
      }
      &:hover {
        span {
          opacity: 1;
          pointer-events: all;
        }
      }
    }
    .icon.info {
      margin-left: 15px;
    }
    .wrapp_address {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: baseline;
      .street {
        width: 70%;
      }
      .small_input {
        width: 10% !important;
        padding: 0 !important;
        padding-left: 15px !important;
      }
      @media(max-width: 430px){
        flex-direction: column;
        .small_input,.street {
          width: 100% !important;
          margin-bottom: 20px;
        }
      }
    }
  }

  .checkout__wrp  {
    .default_input {
      margin-top: 20px;
    }
  }
     .checkout__row form .card .inp_three_row input[type=text]{
    width: calc(33% - 12px) ;
  }
</style>